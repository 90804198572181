const initialState = {
  requestingFetchAddresses: false,
  successfulFetchAddresses: false,
  errorFetchAddresses: false,
  addresses: {},
  requestingCreateAddress: false,
  successfulCreateAddress: false,
  errorsCreateAddress: false,
  address: null,
  requestingReadAddress: false,
  successfulReadAddress: false,
  errorsReadAddress: false,
  rowEdited: null,
  requestingDeleteAddress: false,
  successfulDeleteAddress: false,
  errorsDeleteAddress: false,
  rowDeleted: null,
  requestingUpdateAddress: false,
  successfulUpdateAddress: false,
  errorsUpdateAddress: false,
  rowUpdated: null,
  requestingUploadFileAddress: false,
  successfulUploadFileAddress: false,
  errorsUploadFileAddress: false,
  fileUploaded: null,
  requestingFetchAvailableAddresses: false,
  successfulFetchAvailableAddresses: false,
  errorFetchAvailableAddresses: false,
  addressesAvailable: {},
  requestingFetchAddressesByCity: false,
  successfulFetchAddressesByCity: false,
  errorFetchAddressesByCity: false,
  addressesByCity: {},
  requestingFetchAddressesByZone: false,
  successfulFetchAddressesByZone: false,
  errorFetchAddressesByZone: false,
  addressesByZone: {},
  requestingDisableAddress: false,
  successfulDisableAddress: false,
  errorsDisableAddress: false,
  rowDisabled: null,
  requestingCreateBulkAddress: false,
  successfulCreateBulkAddress: false,
  errorsCreateBulkAddress: false,
  bulkAddress: null,
  requestingFetchAddressesWithoutOperator: false,
  successfulFetchAddressesWithoutOperator: false,
  errorFetchAddressesWithoutOperator: false,
  addressesWithoutOperator: {},
  requestingCreateBulkOperatorAddress: false,
  successfulCreateBulkOperatorAddress: false,
  errorsCreateBulkOperatorAddress: false,
  bulkOperatorAddress: null,
  requestingGenerateGuideAddress: false,
  successfulGenerateGuideAddress: false,
  errorsGenerateGuideAddress: false,
  addressWithGuide: null,
  requestingGenerateAllGuidesAddress: false,
  successfulGenerateAllGuidesAddress: false,
  errorsGenerateAllGuidesAddress: false,
  allGuides: null,
  requestingPatchAddressState: false,
  successfulPatchAddressState: false,
  errorsPatchAddressState: false,
  addressState: null,

  requestingUploadFileTrackingId: false,
  successfulUploadFileTrackingId: false,
  errorsUploadFileTrackingId: false,
  fileUploadedTrackingId: null,

  requestingFetchInvalidAddresses: false,
  successfulFetchInvalidAddresses: false,
  errorFetchInvalidAddresses: false,
  errorUploadInvalidAddresses: false,
  requestingUploadInvalidAddresses: false,
  successfulUploadInvalidAddresses: false,
  fetchInvalidAddressesData: null,

  requestingCreateBulkTrackingIdOperatorAddress: false,
  successfulCreateBulkTrackingIdOperatorAddress: false,
  errorsCreateBulkTrackingIdOperatorAddress: false,
  bulkTrackingIdOperatorAddress: null,

  requestingChangeTrackingIdAddressByOperatorAddress: false,
  successfulChangeTrackingIdAddressByOperatorAddress: false,
  errorsChangeTrackingIdAddressByOperatorAddress: false,
  addressWithChangeTrackingIdAddressByOperator: null,
  requestingFetchAddressesRescheduling: false,
  successfulFetchAddressesRescheduling: false,
  errorFetchAddressesRescheduling: false,
  reschedulings: {},
  requestingUpdateAddressReschedule: false,
  successfulUpdateAddressReschedule: false,
  errorsUpdateAddressReschedule: false,
  reschedule: null,
  requestingFetchAddressesRescheduled: false,
  successfulFetchAddressesRescheduled: false,
  errorFetchAddressesRescheduled: false,
  rescheduled: {},
  requestingUpdateGuidesState: false,
  successfulUpdateGuidesState: false,
  errorsUpdateGuidesState: false,
  guidesState: null,
  requestingUploadFileAddressesStates: false,
  successfulUploadFileAddressesStates: false,
  errorsUploadFileAddressesStates: false,
  fileUploadedStates: null,
  requestingDeleteSoftAddresses: false,
  successDeleteSoftAddresses: false,
  errorsDeleteSoftAddresses: false,
  deleteSoftAddresses: null,
  requestingReverseAddresses: false,
  successReverseAddresses: false,
  errorsReverseAddresses: false,
  reversedAddresses: null,
  requestingReverseAddress: false,
  successfulReverseAddress: false,
  errorsReverseAddress: false,
  rowReversed: null,
  errorReschedule: false,
  requestingFetchReschedulingUploadDrive: false,
  successfulFetchReschedulingUploadDrive: false,
  errorFetchReschedulingUploadDrive: false,
};

const addressReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ADDRESSES_REQUESTING":
      return {
        ...state,
        requestingFetchAddresses: true,
        successfulFetchAddresses: false,
        errorFetchAddresses: false,
      };
    case "FETCH_ADDRESS_SUCCESS":
      return {
        ...state,
        errorFetchAddresses: false,
        requestingFetchAddresses: false,
        successfulFetchAddresses: true,
        addresses: action.value,
      };
    case "FETCH_ADDRESS_ERROR":
      return {
        ...state,
        errorFetchAddresses: true,
        requestingFetchAddresses: false,
        successfulFetchAddresses: false,
      };
    case "CREATE_ADDRESS_REQUESTING":
      return {
        ...state,
        requestingCreateAddress: true,
        successfulCreateAddress: false,
        errorsCreateAddress: false,
      };
    case "CREATE_ADDRESS_SUCCESS":
      return {
        ...state,
        errorsCreateAddress: false,
        requestingCreateAddress: false,
        successfulCreateAddress: true,
        address: action.value,
      };
    case "CREATE_ADDRESS_ERROR":
      return {
        ...state,
        errorsCreateAddress: true,
        requestingCreateAddress: false,
        successfulCreateAddress: false,
      };
    case "READ_ADDRESS_REQUESTING":
      return {
        ...state,
        requestingReadAddress: true,
        successfulReadAddress: false,
        errorsReadAddress: false,
      };
    case "READ_ADDRESS_SUCCESS":
      return {
        ...state,
        errorsReadAddress: false,
        requestingReadAddress: false,
        successfulReadAddress: true,
        rowEdited: action.value,
      };
    case "READ_ADDRESS_ERROR":
      return {
        ...state,
        errorsReadAddress: true,
        requestingReadAddress: false,
        successfulReadAddress: false,
      };
    case "DELETE_ADDRESS_REQUESTING":
      return {
        ...state,
        requestingDeleteAddress: true,
        successfulDeleteAddress: false,
        errorsDeleteAddress: false,
      };
    case "DELETE_ADDRESS_SUCCESS":
      return {
        ...state,
        errorsDeleteAddress: false,
        requestingDeleteAddress: false,
        successfulDeleteAddress: true,
        rowDeleted: action.value,
      };
    case "DELETE_ADDRESS_ERROR":
      return {
        ...state,
        errorsDeleteAddress: true,
        requestingDeleteAddress: false,
        successfulDeleteAddress: false,
      };
    case "UPDATE_ADDRESS_REQUESTING":
      return {
        ...state,
        requestingUpdateAddress: true,
        successfulUpdateAddress: false,
        errorsUpdateAddress: false,
      };
    case "UPDATE_ADDRESS_SUCCESS":
      return {
        ...state,
        errorsUpdateAddress: false,
        requestingUpdateAddress: false,
        successfulUpdateAddress: true,
        rowUpdated: action.value,
      };
    case "UPDATE_ADDRESS_ERROR":
      return {
        ...state,
        errorsUpdateAddress: true,
        requestingUpdateAddress: false,
        successfulUpdateAddress: false,
      };
    case "UPLOAD_ADDRESS_REQUESTING":
      return {
        ...state,
        requestingUploadFileAddress: true,
        successfulUploadFileAddress: false,
        errorsUploadFileAddress: false,
      };
    case "UPLOAD_ADDRESS_SUCCESS":
      return {
        ...state,
        errorsUploadFileAddress: false,
        requestingUploadFileAddress: false,
        successfulUploadFileAddress: true,
        fileUploaded: action.value,
      };
    case "UPLOAD_ADDRESS_ERROR":
      return {
        ...state,
        errorsUploadFileAddress: true,
        requestingUploadFileAddress: false,
        successfulUploadFileAddress: false,
      };
    case "DISABLE_ADDRESS_REQUESTING":
      return {
        ...state,
        requestingDisableAddress: true,
        successfulDisableAddress: false,
        errorsDisableAddress: false,
      };
    case "DISABLE_ADDRESS_SUCCESS":
      return {
        ...state,
        errorsDisableAddress: false,
        requestingDisableAddress: false,
        successfulDisableAddress: true,
        rowDisabled: action.value,
      };
    case "DISABLE_ADDRESS_ERROR":
      return {
        ...state,
        errorsDisableAddress: true,
        requestingDisableAddress: false,
        successfulDisableAddress: false,
      };
    case "RESET_ADDRESS_FORM":
      return {
        ...state,
        requestingCreateAddress: false,
        successfulCreateAddress: false,
        errorsCreateAddress: false,
        address: null,
        requestingReadAddress: false,
        successfulReadAddress: false,
        errorsReadAddress: false,
        rowEdited: null,
        requestingDeleteAddress: false,
        successfulDeleteAddress: false,
        rowDeleted: null,
        requestingUpdateAddress: false,
        successfulUpdateAddress: false,
        errorsUpdateAddress: false,
        rowUpdated: null,
        requestingUploadFileAddress: false,
        successfulUploadFileAddress: false,
        errorsUploadFileAddress: false,
        fileUploaded: null,
        requestingFetchAvailableAddresses: false,
        successfulFetchAvailableAddresses: false,
        errorFetchAvailableAddresses: false,        
        requestingFetchInvalidAddresses: false,
        successfulFetchInvalidAddresses: false,
        errorFetchInvalidAddresses: false,
        errorUploadInvalidAddresses: false,
        requestingUploadInvalidAddresses: false,
        successfulUploadInvalidAddresses: false,
        addressesAvailable: {},
        requestingDisableAddress: false,
        successfulDisableAddress: false,
        errorsDisableAddress: false,
        rowDisabled: null,
        requestingCreateBulkAddress: false,
        successfulCreateBulkAddress: false,
        errorsCreateBulkAddress: false,
        bulkAddress: null,
        requestingFetchAddressesWithoutOperator: false,
        successfulFetchAddressesWithoutOperator: false,
        errorFetchAddressesWithoutOperator: false,
        addressesWithoutOperator: {},
        requestingCreateBulkOperatorAddress: false,
        successfulCreateBulkOperatorAddress: false,
        errorsCreateBulkOperatorAddress: false,
        bulkOperatorAddress: null,
        requestingGenerateGuideAddress: false,
        successfulGenerateGuideAddress: false,
        errorsGenerateGuideAddress: false,
        addressWithGuide: null,
        requestingGenerateAllGuidesAddress: false,
        successfulGenerateAllGuidesAddress: false,
        errorsGenerateAllGuidesAddress: false,
        allGuides: null,
        requestingPatchAddressState: false,
        successfulPatchAddressState: false,
        errorsPatchAddressState: false,
        addressState: null,
        requestingFetchAddressesRescheduling: false,
        successfulFetchAddressesRescheduling: false,
        errorFetchAddressesRescheduling: false,
        reschedulings: {},
        requestingUpdateAddressReschedule: false,
        successfulUpdateAddressReschedule: false,
        errorsUpdateAddressReschedule: false,
        reschedule: null,
        requestingFetchAddressesRescheduled: false,
        successfulFetchAddressesRescheduled: false,
        errorFetchAddressesRescheduled: false,
        rescheduled: {},
        requestingUpdateGuidesState: false,
        successfulUpdateGuidesState: false,
        errorsUpdateGuidesState: false,
        guidesState: null,
        requestingUploadFileAddressesStates: false,
        successfulUploadFileAddressesStates: false,
        errorsUploadFileAddressesStates: false,
        fileUploadedStates: null,
        requestingDeleteSoftAddresses: false,
        successDeleteSoftAddresses: false,
        errorsDeleteSoftAddresses: false,
        deleteSoftAddresses: null,
        requestingReverseAddresses: false,
        successReverseAddresses: false,
        errorsReverseAddresses: false,
        reversedAddresses: null,
        requestingReverseAddress: false,
        successfulReverseAddress: false,
        errorsReverseAddress: false,
        rowReversed: null,
      };
    case "RESET":
      return initialState;
    case "FETCH_AVAILABLE_ADDRESSES_REQUESTING":
      return {
        ...state,
        requestingFetchAvailableAddresses: true,
        successfulFetchAvailableAddresses: false,
        errorsFetchAvailableAddresses: false,
      };
    case "FETCH_AVAILABLE_ADDRESS_SUCCESS":
      return {
        ...state,
        errorFetchAvailableAddresses: false,
        requestingFetchAvailableAddresses: false,
        successfulFetchAvailableAddresses: true,
        addressesAvailable: action.value,
      };
    case "FETCH_AVAILABLE_ADDRESS_ERROR":
      return {
        ...state,
        errorFetchAvailableAddresses: true,
        requestingFetchAvailableAddresses: false,
        successfulFetchAvailableAddresses: false,
      };
    case "FETCH_INVALID_ADDRESSES_REQUESTING":
      return {
        ...state,
        requestingFetchInvalidAddresses: true,
        successfulFetchInvalidAddresses: false,
        errorsFetchInvalidAddresses: false,
      };
    case "FETCH_INVALID_ADDRESSES_SUCCESS":
      return {
        ...state,
        errorFetchInvalidAddresses: false,
        requestingFetchInvalidAddresses: false,
        successfulFetchInvalidAddresses: true,
      };
    case "FETCH_INVALID_ADDRESSES_ERROR":
      return {
        ...state,
        errorFetchInvalidAddresses: true,
        requestingFetchInvalidAddresses: false,
        successfulFetchInvalidAddresses: false,
      };
    case "FETCH_INVALID_ADDRESSES_DONE":
      return {
        ...state,
        errorFetchInvalidAddresses: false,
        requestingFetchInvalidAddresses: false,
        successfulFetchInvalidAddresses: false,
      };
    
    
    case "UPDATE_UPLOAD_INVALID_ADDRESS_REQUESTING":
      return {
        ...state,
        errorUploadInvalidAddresses: false,
        requestingUploadInvalidAddresses: true,
        successfulUploadInvalidAddresses: false,
      };
    case "UPDATE_UPLOAD_INVALID_ADDRESS_SUCCESS":
      return {
        ...state,
        errorUploadInvalidAddresses: false,
        requestingUploadInvalidAddresses: false,
        successfulUploadInvalidAddresses: true,
        fetchInvalidAddressesData: action.value,
      };
    case "UPDATE_UPLOAD_INVALID_ADDRESS_ERROR":
      return {
        ...state,
        errorUploadInvalidAddresses: true,
        requestingUploadInvalidAddresses: false,
        successfulUploadInvalidAddresses: false,
      };
    case "UPDATE_UPLOAD_INVALID_ADDRESS_DONE":
      return {
        ...state,
        errorUploadInvalidAddresses: false,
        requestingUploadInvalidAddresses: false,
        successfulUploadInvalidAddresses: false,
      };
    case "FETCHBYCITY_ADDRESSES_REQUESTING":
      return {
        ...state,
        requestingFetchAddressesByCity: true,
        successfulFetchAddressesByCity: false,
        errorFetchAddressesByCity: false,
      };
    case "FETCHBYCITY_ADDRESS_SUCCESS":
      return {
        ...state,
        errorFetchAddressesByCity: false,
        requestingFetchAddressesByCity: false,
        successfulFetchAddressesByCity: true,
        addressesByCity: action.value,
      };
    case "FETCHBYCITY_ADDRESS_ERROR":
      return {
        ...state,
        errorFetchAddressesByCity: true,
        requestingFetchAddressesByCity: false,
        successfulFetchAddressesByCity: false,
      };
    case "FETCHBYZONE_ADDRESSES_REQUESTING":
      return {
        ...state,
        requestingFetchAddressesByZone: true,
        successfulFetchAddressesByZone: false,
        errorFetchAddressesByZone: false,
      };
    case "FETCHBYZONE_ADDRESS_SUCCESS":
      return {
        ...state,
        errorFetchAddressesByZone: false,
        requestingFetchAddressesByZone: false,
        successfulFetchAddressesByZone: true,
        addressesByZone: action.value,
      };
    case "FETCHBYZONE_ADDRESS_ERROR":
      return {
        ...state,
        errorFetchAddressesByZone: true,
        requestingFetchAddressesByZone: false,
        successfulFetchAddressesByZone: false,
      };
    case "CREATE_BULK_ADDRESS_REQUESTING":
      return {
        ...state,
        requestingCreateBulkAddress: true,
        successfulCreateBulkAddress: false,
        errorsCreateBulkAddress: false,
      };
    case "CREATE_BULK_ADDRESS_SUCCESS":
      return {
        ...state,
        errorsCreateBulkAddress: false,
        requestingCreateBulkAddress: false,
        successfulCreateBulkAddress: true,
        bulkAddress: action.value,
      };
    case "CREATE_BULK_ADDRESS_ERROR":
      return {
        ...state,
        errorsCreateBulkAddress: true,
        requestingCreateBulkAddress: false,
        successfulCreateBulkAddress: false,
      };
    case "FETCH_ADDRESSES_WITHOUT_OPERATOR_REQUESTING":
      return {
        ...state,
        requestingFetchAddressesWithoutOperator: true,
        successfulFetchAddressesWithoutOperator: false,
        errorFetchAddressesWithoutOperator: false,
      };
    case "FETCH_ADDRESS_WITHOUT_OPERATOR_SUCCESS":
      return {
        ...state,
        errorFetchAddressesWithoutOperator: false,
        requestingFetchAddressesWithoutOperator: false,
        successfulFetchAddressesWithoutOperator: true,
        addressesWithoutOperator: action.value,
      };
    case "FETCH_ADDRESS_WITHOUT_OPERATOR_ERROR":
      return {
        ...state,
        errorFetchAddressesWithoutOperator: true,
        requestingFetchAddressesWithoutOperator: false,
        successfulFetchAddressesWithoutOperator: false,
      };
    case "CREATE_BULK_OPERATOR_ADDRESS_REQUESTING":
      return {
        ...state,
        requestingCreateBulkOperatorAddress: true,
        successfulCreateBulkOperatorAddress: false,
        errorsCreateBulkOperatorAddress: false,
      };
    case "CREATE_BULK_OPERATOR_ADDRESS_SUCCESS":
      return {
        ...state,
        errorsCreateBulkOperatorAddress: false,
        requestingCreateBulkOperatorAddress: false,
        successfulCreateBulkOperatorAddress: true,
        bulkOperatorAddress: action.value,
      };
    case "CREATE_BULK_OPERATOR_ADDRESS_ERROR":
      return {
        ...state,
        errorsCreateBulkOperatorAddress: true,
        requestingCreateBulkOperatorAddress: false,
        successfulCreateBulkOperatorAddress: false,
      };
    case "GENERATE_GUIDE_ADDRESS_REQUESTING":
      return {
        ...state,
        requestingGenerateGuideAddress: true,
        successfulGenerateGuideAddress: false,
        errorsGenerateGuideAddress: false,
      };
    case "GENERATE_GUIDE_ADDRESS_SUCCESS":
      return {
        ...state,
        errorsGenerateGuideAddress: false,
        requestingGenerateGuideAddress: false,
        successfulGenerateGuideAddress: true,
        addressWithGuide: action.value,
      };
    case "GENERATE_GUIDE_ADDRESS_ERROR":
      return {
        ...state,
        errorsGenerateGuideAddress: true,
        requestingGenerateGuideAddress: false,
        successfulGenerateGuideAddress: false,
      };
    case "GENERATE_ALL_GUIDES_ADDRESS_REQUESTING":
      return {
        ...state,
        requestingGenerateAllGuidesAddress: true,
        successfulGenerateAllGuidesAddress: false,
        errorsGenerateAllGuidesAddress: false,
      };
    case "GENERATE_ALL_GUIDES_ADDRESS_SUCCESS":
      return {
        ...state,
        errorsGenerateAllGuidesAddress: false,
        requestingGenerateAllGuidesAddress: false,
        successfulGenerateAllGuidesAddress: true,
        allGuides: action.value,
      };
    case "GENERATE_ALL_GUIDES_ADDRESS_ERROR":
      return {
        ...state,
        errorsGenerateAllGuidesAddress: true,
        requestingGenerateAllGuidesAddress: false,
        successfulGenerateAllGuidesAddress: false,
      };
    case "UPDATESTATE_ADDRESS_REQUESTING":
      return {
        ...state,
        requestingPatchAddressState: true,
        successfulPatchAddressState: false,
        errorsPatchAddressState: false,
      };
    case "UPDATESTATE_ADDRESS_SUCCESS":
      return {
        ...state,
        errorsPatchAddressState: false,
        requestingPatchAddressState: false,
        successfulPatchAddressState: true,
        addressState: action.value,
      };
    case "UPDATESTATE_ADDRESS_ERROR":
      return {
        ...state,
        errorsPatchAddressState: true,
        requestingPatchAddressState: false,
        successfulPatchAddressState: false,
      };
    case "UPLOAD_TRACKING_ID_REQUESTING":
      return {
        ...state,
        requestingUploadFileTrackingId: true,
        successfulUploadFileTrackingId: false,
        errorsUploadFileTrackingId: false,
      };
    case "UPLOAD_TRACKING_ID_SUCCESS":
      return {
        ...state,
        errorsUploadFileTrackingId: false,
        requestingUploadFileTrackingId: false,
        successfulUploadFileTrackingId: true,
        fileUploadedTrackingId: action.value,
      };
    case "UPLOAD_TRACKING_ID_ERROR":
      return {
        ...state,
        errorsUploadFileTrackingId: true,
        requestingUploadFileTrackingId: false,
        successfulUploadFileTrackingId: false,
      };
    case "CREATE_BULK_TRACKINGID_ADDRESS_REQUESTING":
      return {
        ...state,
        requestingCreateBulkTrackingIdAddress: true,
        successfulCreateBulkTrackingIdAddress: false,
        errorsCreateBulkTrackingIdAddress: false,
      };
    case "CREATE_BULK_TRACKINGID_ADDRESS_SUCCESS":
      return {
        ...state,
        errorsCreateBulkTrackingIdAddress: false,
        requestingCreateBulkTrackingIdAddress: false,
        successfulCreateBulkTrackingIdAddress: true,
        bulkTrackingIdOperatorAddress: action.value,
      };
    case "CREATE_BULK_TRACKINGID_ADDRESS_RESET":
      return {
        ...state,
        errorsCreateBulkTrackingIdAddress: false,
        requestingCreateBulkTrackingIdAddress: false,
        successfulCreateBulkTrackingIdAddress: false,
        bulkTrackingIdOperatorAddress: null,
      };
    case "CREATE_BULK_TRACKINGID_ADDRESS_ERROR":
      return {
        ...state,
        errorsCreateBulkTrackingIdAddress: true,
        requestingCreateBulkTrackingIdAddress: false,
        successfulCreateBulkTrackingIdAddress: false,
      };

    case "GHANGE_OPERATOR_ADDRESS_REQUESTING":
      return {
        ...state,
        requestingChangeTrackingIdAddressByOperatorAddress: true,
        successfulChangeTrackingIdAddressByOperatorAddress: false,
        errorsChangeTrackingIdAddressByOperatorAddress: false,
      };
    case "GHANGE_OPERATOR_ADDRESS_SUCCESS":
      return {
        ...state,
        requestingChangeTrackingIdAddressByOperatorAddress: false,
        successfulChangeTrackingIdAddressByOperatorAddress: true,
        errorsChangeTrackingIdAddressByOperatorAddress: false,
        addressWithChangeTrackingIdAddressByOperator: action.value,
      };
    case "GHANGE_OPERATOR_ADDRESS_RESET":
      return {
        ...state,
        requestingChangeTrackingIdAddressByOperatorAddress: false,
        successfulChangeTrackingIdAddressByOperatorAddress: false,
        errorsChangeTrackingIdAddressByOperatorAddress: false,
        addressWithChangeTrackingIdAddressByOperator: null,
      };
    case "GHANGE_OPERATOR_ADDRESS_ERROR":
      return {
        ...state,
        requestingChangeTrackingIdAddressByOperatorAddress: false,
        successfulChangeTrackingIdAddressByOperatorAddress: false,
        errorsChangeTrackingIdAddressByOperatorAddress: true,
        addressWithChangeTrackingIdAddressByOperator: null,
      };
    case "FETCH_ADDRESSES_RESCHEDULING_REQUESTING":
      return {
        ...state,
        requestingFetchAddressesRescheduling: true,
        successfulFetchAddressesRescheduling: false,
        errorFetchAddressesRescheduling: false,
      };
    case "FETCH_RESCHEDULING_UPLOAD_DRIVE_REQUESTING":
        return {
          ...state,
          requestingFetchReschedulingUploadDrive: true,
          successfulFetchReschedulingUploadDrive: false,
          errorFetchReschedulingUploadDrive: false,
        };  
    case "FETCH_ADDRESSES_RESCHEDULING_SUCCESS":
      return {
        ...state,
        errorFetchAddressesRescheduling: false,
        requestingFetchAddressesRescheduling: false,
        successfulFetchAddressesRescheduling: true,
        reschedulings: action.value,
      };
    case "FETCH_ADDRESSES_RESCHEDULING_ERROR":
      return {
        ...state,
        errorFetchAddressesRescheduling: true,
        requestingFetchAddressesRescheduling: false,
        successfulFetchAddressesRescheduling: false,
      };
    case "UPDATE_ADDRESS_RESCHEDULE_REQUESTING":
      return {
        ...state,
        requestingUpdateAddressReschedule: true,
        successfulUpdateAddressReschedule: false,
        errorsUpdateAddressReschedule: false,
      };
    case "UPDATE_ADDRESS_RESCHEDULE_SUCCESS":
      return {
        ...state,
        errorsUpdateAddressReschedule: false,
        requestingUpdateAddressReschedule: false,
        successfulUpdateAddressReschedule: true,
        reschedule: action.value,
      };
    case "UPDATE_ADDRESS_RESCHEDULE_ERROR":
      return {
        ...state,
        errorsUpdateAddressReschedule: true,
        requestingUpdateAddressReschedule: false,
        successfulUpdateAddressReschedule: false,
      };
    case "FETCH_ADDRESSES_RESCHEDULED_REQUESTING":
      return {
        ...state,
        requestingFetchAddressesRescheduled: true,
        successfulFetchAddressesRescheduled: false,
        errorFetchAddressesRescheduled: false,
      };
    case "FETCH_ADDRESSES_RESCHEDULED_SUCCESS":
      return {
        ...state,
        errorFetchAddressesRescheduled: false,
        requestingFetchAddressesRescheduled: false,
        successfulFetchAddressesRescheduled: true,
        rescheduled: action.value,
      };
    case "FETCH_ADDRESSES_RESCHEDULED_ERROR":
      return {
        ...state,
        errorFetchAddressesRescheduled: true,
        requestingFetchAddressesRescheduled: false,
        successfulFetchAddressesRescheduled: false,
      };
    case "UPDATE_GUIDES_STATE_REQUESTING":
      return {
        ...state,
        requestingUpdateGuidesState: true,
        successfulUpdateGuidesState: false,
        errorsUpdateGuidesState: false,
      };
    case "UPDATE_GUIDES_STATE_SUCCESS":
      return {
        ...state,
        errorsUpdateGuidesState: false,
        requestingUpdateGuidesState: false,
        successfulUpdateGuidesState: true,
        guidesState: action.value,
      };
    case "UPDATE_GUIDES_STATE_ERROR":
      return {
        ...state,
        errorsUpdateGuidesState: true,
        requestingUpdateGuidesState: false,
        successfulUpdateGuidesState: false,
      };
    case "UPLOAD_ADDRESSES_STATES_REQUESTING":
      return {
        ...state,
        requestingUploadFileAddressesStates: true,
        successfulUploadFileAddressesStates: false,
        errorsUploadFileAddressesStates: false,
      };
    case "UPLOAD_ADDRESSES_STATES_SUCCESS":
      return {
        ...state,
        errorsUploadFileAddressesStates: false,
        requestingUploadFileAddressesStates: false,
        successfulUploadFileAddressesStates: true,
        fileUploadedStates: action.value,
      };
    case "UPLOAD_ADDRESSES_STATES_ERROR":
      return {
        ...state,
        errorsUploadFileAddressesStates: true,
        requestingUploadFileAddressesStates: false,
        successfulUploadFileAddressesStates: false,
      };
    case "DELETE_ADDRESSES_REQUESTING":
      return {
        ...state,
        requestingDeleteSoftAddresses: true,
        successDeleteSoftAddresses: false,
        errorsDeleteSoftAddresses: false,
      };
    case "DELETE_ADDRESSES_SUCCESS":
      return {
        ...state,
        errorsDeleteSoftAddresses: false,
        requestingDeleteSoftAddresses: false,
        successDeleteSoftAddresses: true,
        deleteSoftAddresses: action.value,
      };
    case "DELETE_ADDRESSES_ERROR":
      return {
        ...state,
        errorsDeleteSoftAddresses: true,
        requestingDeleteSoftAddresses: false,
        successDeleteSoftAddresses: false,
      };
    case "REVERSE_ADDRESSES_REQUESTING":
      return {
        ...state,
        requestingReverseAddresses: true,
        successReverseAddresses: false,
        errorsReverseAddresses: false,
      };
    case "REVERSE_ADDRESSES_SUCCESS":
      return {
        ...state,
        errorsReverseAddresses: false,
        requestingReverseAddresses: false,
        successReverseAddresses: true,
        reversedAddresses: action.value,
      };
    case "REVERSE_ADDRESSES_ERROR":
      return {
        ...state,
        errorsReverseAddresses: true,
        requestingReverseAddresses: false,
        successReverseAddresses: false,
      };
    case "REVERSE_ADDRESS_REQUESTING":
      return {
        ...state,
        requestingReverseAddress: true,
        successReverseAddress: false,
        errorsReverseAddress: false,
      };
    case "REVERSE_ADDRESS_SUCCESS":
      return {
        ...state,
        errorsReverseAddress: false,
        requestingReverseAddress: false,
        successReverseAddress: true,
        rowReversed: action.value,
      };
    case "REVERSE_ADDRESS_ERROR":
      return {
        ...state,
        errorsReverseAddress: true,
        requestingReverseAddress: false,
        successReverseAddress: false,
      };
    case "RESET_ADDRESS_RESCHEDULING_FORM":
      return {
        ...state,
        rowEdited: null,
      };
    default:
      return state;
  }
};

export default addressReducer;
