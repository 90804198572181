import { useEffect } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Button, Modal } from "react-bootstrap";
import Select from 'react-select';
import { isNullOrUndefined } from "../../../../../utils/functionsUtils";

const InventoryProductLocation = ({ 
    show, 
    handleClose, 
    locationInfo, 
    reduxGetWarehouses,
    warehouses,
    reduxGetWarehouseById,
    rowEdited,
    reduxGetProductLocation,
    productLocations,
    reduxCreateProductLocation,
    reduxGetWarehouseInventoryByShipper,
}) => {
    const { control, setValue, handleSubmit, reset} = useForm({ defaultValues: { warehouse: null, col: '', stowage: '', rack: '', level: '' } });

    useEffect(() => {
        reset();
        if (show) {
            reduxGetWarehouses({
                page: 1,
                offset: 1000,
            });

            if (locationInfo && locationInfo.idWarehouse) {
                reduxGetWarehouseById({
                    id: locationInfo.idWarehouse
                });
            }

            if (locationInfo && locationInfo.idInventory) {
                reduxGetProductLocation({
                    idInventory: locationInfo.idInventory
                });
            }
        }
    }, [show, locationInfo, reduxGetWarehouses, reduxGetWarehouseById, reduxGetProductLocation]);

    useEffect(() => {
        if (!isNullOrUndefined(rowEdited)) {
            setValue('warehouse', {
                value: rowEdited.idWarehouse,
                label: `${rowEdited.name || rowEdited.warehouseCode}`,
            });
        }
    }, [rowEdited, setValue]);
    

    useEffect(() => {
        if (productLocations.length > 0) {
            setValue('col', productLocations[0].col || '');
            setValue('rack', productLocations[0].rack || '');
            setValue('level', productLocations[0].level || '');            
            setValue('stowage', productLocations[0].stowage || '');
        }
    }, [productLocations, setValue]);

    const onSubmit = (data) => {
        const { col, stowage, rack, level } = data;
        reduxCreateProductLocation({
            col: col.toUpperCase(),
            stowage: stowage.toUpperCase(),
            rack: rack.toUpperCase(),
            level: level.toUpperCase(),
            idInventory: locationInfo.idInventory,
        });
    
        reduxGetWarehouseInventoryByShipper({
            page: 1,
            shipper: locationInfo.idCompany,
            warehouses: locationInfo.idWarehouse,
        });

    };

    const customStyles = {
        menu: (base) => ({ ...base, zIndex: 9999 }),
        valueContainer: (baseStyles) => ({ ...baseStyles, maxHeight: "3.5vw", overflowY: "auto" }),
        control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap" })
    };

    return (
        <Modal 
            show={show} 
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton>
                <div className="d-flex justify-content-between align-items-center">
                    <Modal.Title>Ubicación en Bodega</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className='form-group col'>
                            <label htmlFor='warehouse' className='form-label'>
                                Bodega *
                            </label>
                            <Controller
                                control={control}
                                name="warehouse"
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isClearable
                                        styles={customStyles}
                                        options={
                                            warehouses && warehouses.items
                                                ? warehouses.items.filter(e=> e.isActive === true)
                                                .map(ele => ({
                                                    value: ele.idWarehouse,
                                                    label: `${ele.name || ele.warehouseCode}`,
                                                }))
                                                : []
                                        }
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-3">
                            <label htmlFor="col" className="form-label">
                                Pasillo
                            </label>
                            <Controller
                                control={control}
                                name="col"
                                render={({ field }) => (
                                    <input
                                        type='text'
                                        {...field}
                                        className='form-control'
                                    />
                                )}
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="rack" className="form-label">
                                Rack
                            </label>
                            <Controller
                                control={control}
                                name="rack"
                                render={({ field }) => (
                                    <input
                                        type='text'
                                        {...field}
                                        className='form-control'
                                    />
                                )}
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="level" className="form-label">
                                Nivel
                            </label>
                            <Controller
                                control={control}
                                name="level"
                                render={({ field }) => (
                                    <input
                                        type='text'
                                        {...field}
                                        className='form-control'
                                    />
                                )}
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="stowage" className="form-label">
                                Estiba *
                            </label>
                            <Controller
                                control={control}
                                name="stowage"
                                render={({ field }) => (
                                    <input
                                        type='text'
                                        {...field}
                                        required={true}
                                        className='form-control'
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                        <Button type="submit" variant="primary">
                            Guardar
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        warehouses: state.warehouseState.warehouses,
        warehouse: state.warehouseState.warehouse,
        productLocations: state.warehouseState.productLocations,
        rowEdited: state.warehouseState.rowEdited,
        productLocation: state.warehouseState.productLocation,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetWarehouses: (payload) =>
            dispatch({
                type: "FETCH_WAREHOUSES_REQUEST",
                value: payload,
            }),
        reduxGetWarehouseById: (payload) =>
            dispatch({
                type: "READ_WAREHOUSE_REQUEST",
                value: payload,
            }),
        reduxGetProductLocation: (payload) =>
            dispatch({
                type: "GET_PRODUCTLOCATION_REQUEST",
                value: payload,
            }),
        reduxCreateProductLocation: (payload) =>
            dispatch({
                type: "CREATE_PRODUCTLOCATION_REQUEST",
                value: payload,
            }),
        reduxGetWarehouseInventoryByShipper: (payload) =>
            dispatch({
                type: "READ_WAREHOUSE_SHIPPERINVENTORY_REQUEST",
                value: payload,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryProductLocation);