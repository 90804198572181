import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { element } from "prop-types";
import ReactPaginate from "react-paginate";
import SearchTable from "../Common/globalTableSearch";
import { isNullOrUndefined } from "../../utils/functionsUtils";

let timeOut = null;

const Grid = ({
  disable,
  defaultValue,
  cols,
  data = [],
  page,
  pages,
  onChangeRange,
  onChangePage,
  onChangeSearch,
  actionsButton,
  total,
  showRange = true,
  showFilters = true,
  offset = 10,
  ...props
}) => {
  const ranges = [10, 25, 50, 100, 500, 1000];

  const showPaginationNumbers = (pageNumbers) => {
    let paginationNumbers = [];
    if (pageNumbers) {
      let showMax = 5;
      let endPage;
      let startPage;
      if (pageNumbers <= showMax) {
        startPage = 1;
        endPage = pageNumbers.length;
      } else {
        startPage = page;
        if (
          startPage != pageNumbers.length &&
          startPage + 1 != pageNumbers.length
        ) {
          endPage = page + showMax - 1;
        } else {
          endPage = pageNumbers.length;
        }
      }
      for (let i = startPage; i <= endPage; i++) {
        paginationNumbers.push(i);
      }
      return showRenderPageNumbers(paginationNumbers);
    }
  };

  const showRenderPageNumbers = (paginationNumbers) => {
    if (paginationNumbers) {
      let result = paginationNumbers.map((number) => {
        return (
          <Pagination.Item
            key={number}
            active={number === page}
            onClick={() => onChangePage(number)}
          >
            {number}
          </Pagination.Item>
        );
      });
      return result;
    }
  };
  const [currentGlobalFilter, setCurrentGlobalFilter] = useState('');

  const [value, setValue] = useState(null);

  const onChangeValue = (event) => {
    setValue(event.target.value)
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onChangeSearch(`${currentGlobalFilter}|${encodeURIComponent(value.trim())}`);
  };

  const handleChangeFilter = (val)  => {
    let dev = val.currentTarget.value;
    setCurrentGlobalFilter(dev);
    setValue('')
    if (dev === '') {
      onChangeSearch('')
    }
  }
  const handleSubmitFilter = (e) => {
    e.preventDefault();
    onChangeSearch(`${currentGlobalFilter}|${encodeURIComponent(value.trim())}`);
  }
  const onEnter = (event) => event.key === "Enter" && onSubmit(event);

  return (
    <div className={disable ? 'container-disabled' : ''}>
      <div className="d-flex mb-3 justify-content-between align-items-center">
        { showRange && (
            <div className="w-auto">
              <div className="dataTables_length" id="dataTable_length">
                  <span>Mostrar </span>{" "}
                  <select
                      name="dataTable_length"
                      aria-controls="dataTable"
                      className="custom-select custom-select-sm form-control form-control-sm w-auto"
                      onChange={(event) => {
                        onChangeRange(event.target.value);
                      }}
                      value={offset}
                  >
                    {ranges.map((elem, index) => (
                        <option key={index} value={elem}>
                          {elem}
                        </option>
                    ))}
                  </select>{" "}
                <span> Registros</span>
              </div>
            </div>
          )}
        { showFilters && (
          <div className="ml-auto">
            <div id="dataTable_filter" className="dataTables_filter">
              <form onSubmit={handleSubmitFilter}>
              <div className="input-group">
                <SearchTable columns={cols} onChangeSearch={handleChangeFilter} filterValue={value} filterEnabled={currentGlobalFilter === ''} handleFilterValueChange={onChangeValue}/>
                <div className="input-group-append">
                  {(currentGlobalFilter !== '' && value !== '') && (
                      <button
                          className="btn btn-primary"
                          type="submit"
                          onClick={(e) => onSubmit(e)}
                      >
                        <i className="fas fa-search"></i>
                      </button>
                  )}
                </div>
              </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <div className="table-responsive">
        <div
          id="dataTable_wrapper"
          className="dataTables_wrapper dt-bootstrap4"
        >

          {actionsButton &&
            actionsButton.isVisible &&
            actionsButton.buttons &&
            actionsButton.buttons.length > 0 && (
              <div className={"row mb-3 justify-content-start"}>
                {actionsButton.buttons.map((element) => {
                  if (element.isVisible) {
                    if (element.render) {
                      if (
                        React.isValidElement(element.render) ||
                        typeof element.render === "string"
                      ) {
                        return element.render;
                      }
                      if (typeof element.render === "function") {
                        return element.render();
                      }
                    }
                    return (
                      <div className={"col-auto"}>
                        <button
                          className={"btn btn-sm " + element.classNames}
                          onClick={element.onclick}
                        >
                          {element.label}
                        </button>
                      </div>
                    );
                  }
                })}
              </div>
            )}

          <table className={"table table-bordered dataTable table-sm "+ props.className}>
            <thead>
              <tr>
                {cols.map((headerItem, index) => (
                  headerItem.hasOwnProperty('visible') && !headerItem.visible ? <></> :
                    <th className="text-nowrap" key={index}>
                      {typeof headerItem.title == "string"
                        ? headerItem.title
                        : headerItem.title()}
                    </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((item, index) =>
                  item.isActive === false ? (
                    <tr
                      key={index}
                      style={{
                        backgroundColor:
                          item.isActive === false ? "#6492ba" : "#fff",
                        color: item.isActive === false ? "#fff" : "#858796",
                      }}
                    >
                      {cols.map((col, key) => (
                        col.hasOwnProperty('visible') && !col.visible ? <></> :
                          <td className="text-nowrap" key={key}>
                            {col.render(item)}
                          </td>
                      ))}
                    </tr>
                  ) : (
                    <tr key={index}>
                      {cols.map((col, key) => (
                        col.hasOwnProperty('visible') && !col.visible ? <></> :
                          <td className="text-nowrap" key={key}>
                            {col.render(item)}
                          </td>
                      ))}
                    </tr>
                  )
                )
              ) : (
                <tr className="odd">
                  <td
                    valign="top"
                    colSpan={cols.length}
                    className="dataTables_empty"
                  >
                    No se encontraron registros
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {!isNullOrUndefined(pages) && (
        <div className="row mt-3">
          <div className="col-sm-12 col-md-5">
            <strong>{total}</strong> registros encontrados | página{" "}
            <strong>{page}</strong> de <strong>{pages}</strong>
          </div>
          {total > 0 &&
            <div className="col-sm-12 col-md-7">
              <Pagination className="float-right">
                <ReactPaginate
                  previousLabel="Anterior"
                  nextLabel="Siguiente"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageCount={pages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={(page) => onChangePage(page.selected + 1)}
                  containerClassName="pagination"
                  activeClassName="active"
                  forcePage={page === 1 ? 0 : page - 1}
                />
              </Pagination>
            </div>
          }
        </div>
      )}
    </div>
  );
};

export default Grid;
